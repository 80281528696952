import { CurrencyPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../modules/material.module';
import { AuthGuard } from '../services/auth/auth-guard';
import { JwtInterceptor } from '../services/auth/jwt-interceptor';
import { CcService } from '../services/cc.service';
import { CompanyService } from '../services/company.service';
import { CountService } from '../services/count.service';
import { CustomerService } from '../services/customer.service';
import { LocationService } from '../services/location.service';
import { PasService } from '../services/pas.service';
//import { PasCodeService } from '../services/pascode.service';
import { RouterService } from '../services/router.service';
import { UploadFilesService } from '../services/upload-files.service';
import { UserService } from '../services/user.service';
import { AccountListComponent } from './accounting/list/accountlist.component';
import { AccountingComponent } from './accounting/menu/accounting.component';
import { AppComponent } from './app.component';
import { CcAddComponent } from './cc/add/ccadd.component';
import { CcEditComponent } from './cc/edit/ccedit.component';
import { CcListComponent } from './cc/list/cclist.component';
import { CompanyaddComponent } from './company/add/companyadd.component';
import { CompcfgComponent } from './company/cfg/compcfg.component';
import { CompanyComponent } from './company/company.component';
import { CompanyeditComponent } from './company/edit/companyedit.component';
import { CompmoreComponent } from './company/more/compmore.component';
import { CustomercxcComponent } from './customer/cxcframe/customercxc.component';
import { CustomerdetailComponent } from './customer/detail/customerdetail.component';
import { CustomereditComponent } from './customer/edit/customeredit.component';
import { LicensesListComponent } from './customer/licenses/list/licenseslist.component';
import { CustomerListComponent } from './customer/list/customerlist.component';
import { CustmoreComponent } from './customer/more/custmore.component';
import { DataComponent } from './data/data.component';
import { LocationaddComponent } from './data/location/add/locationadd.component';
import { LocationsListComponent } from './data/location/list/locationslist.component';
import { Factinfcie01Component } from './factinfcie01/factinfcie01.component';
import { HomeComponent } from './home/home.component';
import { cxc01Component } from './informes/cxc/cxc01.component';
import { DailypurchaseComponent } from './informes/dailypurchase/dailypurchase.component';
import { elecdocu01Component } from './informes/elecdocu/elecdocu01.component';
import { InformesComponent } from './informes/informes.component';
import { supervisedpas01Component } from './informes/supervisedpas/supervisedpas01.component';
import { ArnfilesupComponent } from './inventory/arnfilesup/arnfilesup.component';
import { CancelcountComponent } from './inventory/count/cancelcount/cancelcount.component';
import { CcSelectComponent } from './inventory/count/ccselect/ccselect.component';
import { CcSelectGsComponent } from './inventory/count/ccselectgs/ccselectgs.component';
import { ClosecountComponent } from './inventory/count/closecount/closecount.component';
import { DiffreportComponent } from './inventory/count/diffreport/diffreport.component';
import { LocationSelectComponent } from './inventory/count/locationselect/locationselect.component';
import { LocdiffrepoComponent } from './inventory/count/locdiffrepo/locdiffrepo.component';
import { LocreportComponent } from './inventory/count/locreport/locreport.component';
import { NumberComponent } from './inventory/count/number/number.component';
import { CountstartComponent } from './inventory/count/start/countstart.component';
import { TocountComponent } from './inventory/count/tocount/tocount.component';
import { InventoryComponent } from './inventory/inventory.component';
import { StockListComponent } from './inventory/stocklist/stocklist.component';
import { LoginComponent } from './login/login.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PasListComponent } from './pas/list/paslist.component';
import { UseraddComponent } from './user/add/useradd.component';
import { UsereditComponent } from './user/edit/useredit.component';
import { UserListComponent } from './user/list/userlist.component';
import { PrmeditComponent } from './user/prm/prmedit.component';
import { PsweditComponent } from './user/psw/pswedit.component';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatDialogModule, MatIconModule, MatTreeModule } from '@angular/material';
import { AccountingService } from '../services/accounting.service';
import { AdService } from '../services/ad.service';
import { BackgroundProcessService } from '../services/backgroundprocess';
import { CourseService } from '../services/course.service';
import { DataTableService } from '../services/dataTable.service';
import { FileService } from '../services/file.service';
import { MenuService } from '../services/menu.service';
import { ModuleService } from '../services/module.service';
import { PasGroupService } from '../services/pasGroup.service';
import { PaymMethService } from '../services/paymmeth.service';
import { PermissionsService } from '../services/permissions.service';
import { ProductionService } from '../services/production.service';
import { RegistryService } from '../services/registry.service';
import { ReportService } from '../services/report.service';
import { SellerService } from '../services/seller.service';
import { StockService } from '../services/stock.service';
import { SystemInternalService } from '../services/systemInternal.service';
import { TaxesService } from '../services/taxes.service';
import { TransferService } from '../services/transfer.service';
import { WikiService } from '../services/wiki.service';
import { AccountAddComponent } from './accounting/add/accountadd.component';
import { AccountEditComponent } from './accounting/edit/accountedit.component';
import { AddAdComponent } from './ads/Add/addad.component';
import { AdListComponent } from './ads/list/adlist.component';
import { CCSelectV2Component } from './cc/select/CCSelectV2.component';
import { CompanyGraphComponent } from './company/companyGraph.component';
import { MyModulesComponent } from './company/module/mymodules.component';
import { MyCompanyComponent } from './company/myCompany.component';
import { ContentBasicComponent } from './courses/contentframe/contentbasic.component';
import { CoursesListComponent } from './courses/courseslist.component';
import { CustomerAddComponent } from './customer/add/customeradd.component';
import { ActivateComponent } from './customer/licenses/activate/activate.component';
import { LicenseAddComponent } from './customer/licenses/add/licenseadd.component';
import { LicenseDetailComponent } from './customer/licenses/detail/licensedetail.component';
import { StatusComponent } from './customer/licenses/status/status.component';
import { LocationTiqueAssignComponent } from './data/prdlocation/Assign/locationTiqueAssign.component';
import { AddLocationProductionComponent } from './data/prdlocation/Production/addlocationproduction.component';
import { EditLocationProductionComponent } from './data/prdlocation/Production/editlocationproduction.component';
import { AccountingReportComponent } from './informes/Accounting/accountingreport.component';
import { SellerSalesComponent } from './informes/sellersales/sellerSales.component';
import { StockValuedComponent } from './informes/stock/stockvalued.component';
import { TotalCountComponent } from './inventory/count/report/totalCount.component';
import { OrdersComponent } from './inventory/orders/orders.component';
import { TransferDetailComponent } from './inventory/transfer/transferDetail.component';
import { TransferListComponent } from './inventory/transfer/transferlist.component';
import { ProdLocationComponent } from './KDS/prodLocation/prodLocation.component';
import { KdsComponent } from './KDS/production/kds.component';
import { CustomServLocationComponent } from './KDS/ServLocation/customServLocation.component';
import { EditServLocationComponent } from './KDS/ServLocation/editServLocation.component';
import { PASAddComponent } from './pas/add/pasadd.component';
import { PASEditComponent } from './pas/edit/pasedit.component';
import { PasOptionListComponent } from './pas/options/pasOptionList.component';
import { PASMenuComponent } from './pas/pasmenu.component';
import { PriceEditComponent } from './pas/priceModify/priceedit.component';
import { PriceModifyComponent } from './pas/priceModify/pricemodify.component';
import { AddPASProductionComponent } from './pas/production/addpasproduction.component';
import { PASGroupTiqueAssignComponent } from './pasgroup/Assign/pasGroupTiqueAssign.component';
import { PaymMethAddComponent } from './paymmeth/add/paymmethadd.component';
import { PaymMethEditComponent } from './paymmeth/edit/paymmethedit.component';
import { PaymMethListComponent } from './paymmeth/list/paymmethlist.component';
import { ProviderAddComponent } from './provider/add/provideradd.component';
import { ProviderDetailComponent } from './provider/detail/providerdetail.component';
import { ProviderEditComponent } from './provider/edit/provideredit.component';
import { ProviderListComponent } from './provider/list/providerlist.component';
import { PASStatusComponent } from './system/functionGallery/pasStatus.component';
import { MyUserComponent } from './user/myuser/myuser.component';
import { KeyPswComponent } from './user/psw/keypsw.component';
import { RequestRestorePswComponent } from './user/psw/requestrestorepsw.component';
import { RestorePswComponent } from './user/psw/restorepsw.component';
import { RestorePsw2Component } from './user/psw/restorepsw2.component';
import { ListWikisComponent } from './wiki/listwikis.component';
import { TransactionsListComponent } from './transactions/transactionslist.component';
import { TransactionService } from '../services/transaction.service';
import { TransactionAddComponent } from './transactions/transactionadd.component';
import { PrdLocationService } from '../services/prdLocation.service';
import { PrdLocationsListComponent } from './data/prdlocation/list/prdlocationlist.component';
import { PASPrdService } from '../services/PASPrd.service';
import { SrvLocationService } from '../services/SrvLocation.service';
import { PasOptionsGroupComponent } from './pas/options/pasOptionsGroup.component';
import { AppService } from '../services/app.service';
import { PasOptionsSelectComponent } from './pas/options/pasOptionsSelect.component';

//import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    InformesComponent,
    Factinfcie01Component,
    CompanyComponent,MyCompanyComponent,CompanyGraphComponent,
    CompanyeditComponent,
    UserListComponent,
    UseraddComponent,
    UsereditComponent,
    CompanyaddComponent,
    PsweditComponent,
    CcListComponent,
    CcEditComponent,
    CcAddComponent,
    PrmeditComponent,
    DataComponent,
    CustomerListComponent,
    CustomerdetailComponent,
    cxc01Component,
    LicensesListComponent, ActivateComponent,
    PasListComponent, PASMenuComponent, PriceModifyComponent, PriceEditComponent, PASAddComponent, PASEditComponent, AddPASProductionComponent,
    InventoryComponent,
    ArnfilesupComponent,
    LocationsListComponent, AddLocationProductionComponent, EditLocationProductionComponent, EditServLocationComponent,
    LocationaddComponent, ProdLocationComponent,
    PrdLocationsListComponent,
    CustomServLocationComponent,
    StockListComponent,
    CountstartComponent, TotalCountComponent,
    MyModulesComponent,
    CcSelectComponent,
    LocationSelectComponent,
    TocountComponent,
    LocreportComponent,
    DiffreportComponent,
    CancelcountComponent,
    ClosecountComponent,
    NumberComponent,
    LocdiffrepoComponent,
    DailypurchaseComponent,
    CompcfgComponent,
    supervisedpas01Component,
    CustomercxcComponent,
    elecdocu01Component,
    CompmoreComponent,
    CustmoreComponent,
    CustomereditComponent,
    CcSelectGsComponent,
    AccountListComponent,
    AccountingComponent,
    OrdersComponent,
    CoursesListComponent,
    ContentBasicComponent,
    MyUserComponent,
    StatusComponent,
    ListWikisComponent,
    StockValuedComponent,
    AccountingComponent,
    AccountingReportComponent,
    AccountEditComponent,
    AccountAddComponent,
    PaymMethListComponent,
    AdListComponent,
    AddAdComponent,
    TransferListComponent, TransferDetailComponent,
    LocationTiqueAssignComponent, CCSelectV2Component,
    PASGroupTiqueAssignComponent,PasOptionListComponent,PASStatusComponent,PasOptionsGroupComponent,PasOptionsSelectComponent,
    CustomerAddComponent,
    KdsComponent,
    LicenseDetailComponent, PaymMethEditComponent, PaymMethAddComponent,
    ProviderListComponent, ProviderAddComponent, ProviderEditComponent, ProviderDetailComponent,
    SellerSalesComponent,
    TransactionsListComponent,TransactionAddComponent,
    LicenseAddComponent, RestorePswComponent, RequestRestorePswComponent, RestorePsw2Component, KeyPswComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatTreeModule, MatIconModule, MatButtonModule, MatPaginatorModule,MatDialogModule,
    //FlexLayoutModule,
    RouterModule.forRoot([
      //{ path: '', component: HomeComponent, pathMatch: 'full' },
      //{ path: '', children: [{ path: '', component: LoginComponent }, { path: 'restorepsw/:userid/:key', component: RestorePswComponent, canActivate: [AuthGuard] }], pathMatch: 'full' },
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'login', component: LoginComponent },
      { path: 'informes', component: InformesComponent, canActivate: [AuthGuard] },
      { path: 'factinfcie01', component: Factinfcie01Component, canActivate: [AuthGuard] },
      {path:'mycompany', component:MyCompanyComponent, canActivate:[AuthGuard]},
      { path: 'company', component: CompanyComponent, canActivate: [AuthGuard] },
      { path: 'companyedit', component: CompanyeditComponent, canActivate: [AuthGuard] },
      {path:'companygraph',component:CompanyGraphComponent,canActivate:[AuthGuard]},
      { path: 'userlist', component: UserListComponent, canActivate: [AuthGuard] },
      { path: 'useradd', component: UseraddComponent, canActivate: [AuthGuard] },
      { path: 'useredit', component: UsereditComponent, canActivate: [AuthGuard] },
      { path: 'companyadd', component: CompanyaddComponent, canActivate: [AuthGuard] },
      { path: 'pswedit', component: PsweditComponent, canActivate: [AuthGuard] },
      { path: 'cclist', component: CcListComponent, canActivate: [AuthGuard] },
      { path: 'ccedit', component: CcEditComponent, canActivate: [AuthGuard] },
      { path: 'ccadd', component: CcAddComponent, canActivate: [AuthGuard] },
      { path: 'prmedit', component: PrmeditComponent, canActivate: [AuthGuard] },
      { path: 'data', component: DataComponent, canActivate: [AuthGuard] },
      { path: 'customerlist', component: CustomerListComponent, canActivate: [AuthGuard] },
      { path: 'customerdetail', component: CustomerdetailComponent, canActivate: [AuthGuard] },
      { path: 'cxc01', component: cxc01Component, canActivate: [AuthGuard] },
      { path: 'licenseslist', component: LicensesListComponent, canActivate: [AuthGuard] },
      { path: 'paslist', component: PasListComponent, canActivate: [AuthGuard] },
      { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard] },
      { path: 'arnfilesup', component: ArnfilesupComponent, canActivate: [AuthGuard] },
      { path: 'locationslist', component: LocationsListComponent, canActivate: [AuthGuard] },
      {path:'prdlocationlist', component: PrdLocationsListComponent, canActivate:[AuthGuard]},
      { path: 'locationadd', component: LocationaddComponent, canActivate: [AuthGuard] },
      {path: 'mymodules', component:MyModulesComponent, canActivate:[AuthGuard]},
      {path:'customservlocation', component:CustomServLocationComponent, canActivate:[AuthGuard]},
      { path: 'stocklist', component: StockListComponent, canActivate: [AuthGuard] },
      { path: 'countstart', component: CountstartComponent, canActivate: [AuthGuard] },
      { path: 'ccselect', component: CcSelectComponent, canActivate: [AuthGuard] },
      { path: 'locationselect', component: LocationSelectComponent, canActivate: [AuthGuard] },
      { path: 'tocount', component: TocountComponent, canActivate: [AuthGuard] },
      { path: 'locreport', component: LocreportComponent, canActivate: [AuthGuard] },
      { path: 'diffreport', component: DiffreportComponent, canActivate: [AuthGuard] },
      { path: 'cancelcount', component: CancelcountComponent, canActivate: [AuthGuard] },
      { path: 'closecount', component: ClosecountComponent, canActivate: [AuthGuard] },
      { path: 'number', component: NumberComponent, canActivate: [AuthGuard] },
      { path: 'locdiffrepo', component: LocdiffrepoComponent, canActivate: [AuthGuard] },
      { path: 'dailypurchase', component: DailypurchaseComponent, canActivate: [AuthGuard] },
      { path: 'supervisedpas01', component: supervisedpas01Component, canActivate: [AuthGuard] },
      { path: 'compcfg', component: CompcfgComponent, canActivate: [AuthGuard] },
      { path: 'customercxc', component: CustomercxcComponent, canActivate: [AuthGuard] },
      { path: 'elecdocu01', component: elecdocu01Component, canActivate: [AuthGuard] },
      { path: 'compmore', component: CompmoreComponent, canActivate: [AuthGuard] },
      { path: 'custmore', component: CustmoreComponent, canActivate: [AuthGuard] },
      { path: 'customeredit', component: CustomereditComponent, canActivate: [AuthGuard] },
      { path: 'ccselectgs', component: CcSelectGsComponent, canActivate: [AuthGuard] },     {
        path: 'ccselectv2/:path', component: CCSelectV2Component, canActivate:[AuthGuard]},
      { path: 'accountlist', component: AccountListComponent, canActivate: [AuthGuard] },
      { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
      { path: 'accounting', component: AccountingComponent, canActivate: [AuthGuard] },
      { path: 'contentbasic', component: ContentBasicComponent, canActivate: [AuthGuard] },
      { path: 'courseslist', component: CoursesListComponent, canActivate: [AuthGuard] },
      { path: 'myuser', component: MyUserComponent, canActivate: [AuthGuard] },
      { path: 'status', component: StatusComponent, canActivate: [AuthGuard] },
      { path: 'listwikis', component: ListWikisComponent, canActivate: [AuthGuard] },
      { path: 'stockvalued', component: StockValuedComponent, canActivate: [AuthGuard] },
      { path: 'accountingreport', component: AccountingReportComponent, canActivate: [AuthGuard] },
      { path: 'accountedit', component: AccountEditComponent, canActivate: [AuthGuard] },
      { path: 'accountadd', component: AccountAddComponent, canActivate: [AuthGuard] },
      { path: 'paymmethlist', component: PaymMethListComponent, canActivate: [AuthGuard] },
      { path: 'adlist', component: AdListComponent, canActivate: [AuthGuard] },
      { path: 'addad', component: AddAdComponent, canActivate: [AuthGuard] },
      { path: 'transferlist', component: TransferListComponent, canActivate: [AuthGuard] },
      { path: 'transferdetail', component: TransferDetailComponent, canActivate: [AuthGuard] },
      { path: 'locationtiqueassign', component: LocationTiqueAssignComponent, canActivate: [AuthGuard] },
      { path: 'addlocationproduction', component: AddLocationProductionComponent, canActivate: [AuthGuard] },
      { path: 'editlocationproduction', component: EditLocationProductionComponent, canActivate: [AuthGuard] },
      { path: 'pasgrouptiqueassign', component: PASGroupTiqueAssignComponent, canActivate: [AuthGuard] },
      { path: 'pasadd', component: PASAddComponent, canActivate: [AuthGuard] },
      { path: 'pasoptionlist/:groupid', component: PasOptionListComponent, canActivate: [AuthGuard] },
      {path:'pasoptionsselect/:pasid', component: PasOptionsSelectComponent, canActivate:[AuthGuard]},
      {path:'passtatus', component: PASStatusComponent, canActivate:[AuthGuard]},
      { path: 'customeradd', component: CustomerAddComponent, canActivate: [AuthGuard] },
      { path: 'licensedetail', component: LicenseDetailComponent, canActivate: [AuthGuard] },
      { path: 'paymmethedit', component: PaymMethEditComponent, canActivate: [AuthGuard] },
      { path: 'paymmethadd', component: PaymMethAddComponent, canActivate: [AuthGuard] },
      { path: 'providerlist', component: ProviderListComponent, canActivate: [AuthGuard] },
      { path: 'provideradd', component: ProviderAddComponent, canActivate: [AuthGuard] },
      { path: 'provideredit', component: ProviderEditComponent, canActivate: [AuthGuard] },
      { path: 'providerdetail', component: ProviderDetailComponent, canActivate: [AuthGuard] },
      { path: 'prodlocation', component: ProdLocationComponent, canActivate: [AuthGuard] },
      { path: 'activate', component: ActivateComponent, canActivate: [AuthGuard] },
      { path: 'licenseadd', component: LicenseAddComponent, canActivate: [AuthGuard] },
      { path: 'pasmenu', component: PASMenuComponent, canActivate: [AuthGuard] },
      { path: "pricemodify", component: PriceModifyComponent, canActivate: [AuthGuard] },
      { path: "priceedit", component: PriceEditComponent, canActivate: [AuthGuard] },
      { path: "pasedit", component: PASEditComponent, canActivate: [AuthGuard] },
      { path: "pasoptionsgroup", component: PasOptionsGroupComponent, canActivate: [AuthGuard] },
      { path: "addpasproduction", component: AddPASProductionComponent, canActivate: [AuthGuard] },
      { path: "sellersales", component: SellerSalesComponent, canActivate: [AuthGuard] },
      { path: "requestrestorepsw", component: RequestRestorePswComponent },
      { path: "restorepsw/:userid/:key", component: RestorePswComponent },
      { path: "keypsw", component: KeyPswComponent },
      { path: "kds/:locationid", component: KdsComponent, canActivate: [AuthGuard] },
      {path:"editservlocation/:ccid", component:EditServLocationComponent, canActivate:[AuthGuard]},
      { path: "restorepsw2", component: RestorePsw2Component },
      { path: "transactionslist", component: TransactionsListComponent, canActivate:[AuthGuard] },
      { path: "transactionadd", component: TransactionAddComponent, canActivate: [AuthGuard] },
      { path: "totalcount/:countid", component: TotalCountComponent, canActivate: [AuthGuard] },
      { path: '**', redirectTo: '/login', pathMatch: 'full' }
      //{ path: '**', component: PageNotFoundComponent }
    ]),
    BrowserAnimationsModule
  ],
  //entryComponents:[CustomServLocationComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    CompanyService, RouterService, UserService, CcService, CustomerService, CurrencyPipe, PasService, UploadFilesService,
    LocationService, CountService, FileService, CourseService, RegistryService, PermissionsService, WikiService,SystemInternalService,
    StockService, ReportService, AccountingService, PaymMethService, AdService, TransferService, PasGroupService, DataTableService,
    MenuService, SellerService, TaxesService, ProductionService, ModuleService, BackgroundProcessService, TransactionService, PrdLocationService,
    PASPrdService,SrvLocationService, AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }

